<template>
  <div class="mode-list">
    <div class="course-path-card mb-2 cursor-pointer" @click="$emit('onClickCard', id)">
      <img :src="image" alt="" style="max-height:180px; min-height:180px;">
      <div class="course-path-card-contents">
        <div class="course-card-info d-flex justify-content-between">
          <div class="d-flex align-items-center justify-content-between w-100">
            <span class="catagroy">{{ subTitle }}</span>
            <span class="order d-block" v-if="repeatable === 1">{{ orderNumber }}. Tekrar</span>
          </div>
          <div>
            <i v-if="hasPresent" class="icon-feather-gift"
               uk-tooltip="title: Hediyeli Eğitim; pos: bottom-center"></i>
          </div>
        </div>
        <h3 class="assignment-name"> {{ title }}</h3>
        <a
            :class="bottomClass"
            v-if="!isAssignmentEndDateExpired"
            class="btn btn-success btn-sm text-white"
            type="button"
            @click="$emit('onClickCard', id)"
        >
          <i :class="iconClass"></i> {{ bottomTitle }}
        </a>

        <a :class="bottomClass"
           v-if="isAssignmentEndDateExpired"
           class="btn btn-secondary btn-sm text-secondary"
           type="button"
           @click="$emit('onClickCard', id)"
           uk-tooltip="title: Eğitim bitiş tarihi geçmiştir.; pos: bottom-center">
          <i :class="iconClass"></i> {{ bottomTitle }}
        </a>

        <div class="course-progressbar mt-3" :uk-tooltip="
                    'title:%' +
                    processWidth +
                    ' ' +
                    $t('education.Completed') +
                    '; pos: top-center'
                  ">
          <div :class="processClass" :style="{width: processWidth + '%' }" class="course-progressbar-filler"></div>
        </div>
      </div>
      <div class="course-path-card-footer">
        <div>
          <h5>
            <i class="icon-feather-calendar mr-1"></i>
            {{ time }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'educationCardList',
  props: {
    id: {
      required: false,
      default: 1,
    },
    title: {
      required: true,
    },
    subTitle: {
      required: true,
    },
    repeatable: {
      required: false,
      default: 0,
    },
    orderNumber: {
      required: false,
      default: 0,
    },
    bottomTitle: {
      required: true,
    },
    links: {
      required: true,
    },
    time: {
      required: true,
    },
    image: {
      required: true,
    },
    bottomClass: {
      required: true,
    },
    processWidth: {
      required: true,
    },
    processClass: {
      default: '',
      required: false,
    },
    iconClass: {
      required: true,
    },
    hasPresent: {
      required: true,
    },
    isAssignmentEndDateExpired: {
      required: false,
    },
  }
}

</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.assignment-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order {
  font-size: 13px;
  font-weight: bold;
}
</style>
